import { BorderRadius } from '../../utils/variables';

export const buttonStyle = {
  height: `276px`,
  width: `100%`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  border: `2px solid rgba(0,0,0,0.2)`,
  borderRadius: BorderRadius,
  transition: `all 0.3s ease`,
  padding: `45px`,
  position: `relative`,
};

export const imagePlaceholder = {
  height: `150px`,
  width: `310px`,
  maxWidth: `100%`,
  backgroundColor: `#c4c4c4`,
  marginBottom: `30px`,
};

export const labelStyle = {
  fontSize: `22px`,
};

const checkSize = `30px`;

export const checkStyle = {
  position: `absolute`,
  top: `30px`,
  right: `30px`,
  height: checkSize,
  width: checkSize,
  borderRadius: checkSize,
  border: `2px solid rgba(0,0,0,0.2)`,
  backgroundColor: `#fff`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
};
