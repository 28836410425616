import React, { useContext } from 'react';

import Row from '../Container/row';
import Col from '../Container/column';

import { ButtonPlaceholder } from './placeholder';
import { MainColour } from '../../utils/variables';

import './actions.css';

import loadComponent from '../Loadable';
import ResponsiveContext from '../Responsive/context';
import { FormContext } from '../Form/context';

const ButtonInput = loadComponent('button-input');

export default function QuoteActions({ active, prevStage }) {
  const { windowMD } = useContext(ResponsiveContext);
  const { processing } = useContext(FormContext);
  return (
    <div className="quoteActions">
      <Row align="center" justify="space-between">
        <Col md={{ width: `auto`, order: 2 }}>
          <ButtonInput
            value="Next"
            fallback={
              <ButtonPlaceholder
                colour={MainColour}
                width={!windowMD && `100%`}
              />
            }
            processing={processing}
            style={sharedStyle}
            primary
          />
        </Col>
        <Col className="prev" md={{ width: `auto`, order: 1 }}>
          {active > 0 && (
            <ButtonInput
              onClick={e => prevStage(e)}
              value="Previous"
              fallback={<ButtonPlaceholder width={!windowMD && `100%`} />}
              processing={processing}
              style={sharedStyle}
              secondary
              dark
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

const sharedStyle = {
  width: `180px`,
  maxWidth: `100%`,
};
