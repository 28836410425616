import React, { useContext, useEffect, useState } from 'react';
import Switch from 'react-switch';

import { FormContext } from '../Form/context';

import Row from '../Container/row';
import Col from '../Container/column';

import { ProductPlaceholder } from './placeholder';

import Icons from '../Icons';
import {
  buttonStyle,
  checkStyle,
  imagePlaceholder,
  labelStyle,
} from './styles';

import loadComponent from '../Loadable';

const ButtonHidden = loadComponent('button-hidden');
const Alert = loadComponent('alert');

export default function QuoteStyle({ data }) {
  const { values, handleChange, getValue } = useContext(FormContext);

  const activeProduct = getValue('product');
  const isBifold = activeProduct === 'bi-fold';
  const isCasement = activeProduct === 'casement';

  const activeStyle = getValue('style');
  // const doorStyle = getValue('doors');

  const defaultDoors = 'outwards';

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (isBifold || isCasement) {
      const checkVal = values?.doors ? values?.doors === defaultDoors : true;
      setChecked(checkVal);
      handleChange({
        target: { name: 'doors', value: values?.doors || defaultDoors },
      });
    } else {
      handleChange({
        target: { name: 'doors', value: undefined },
      });
    }
  }, []);

  const setStyle = (e, id) => {
    e.preventDefault();
    handleChange({ target: { name: 'style', value: id } });
  };

  const setDoors = () => {
    const newVal = !checked;
    setChecked(newVal);
    handleChange({
      target: { name: 'doors', value: newVal ? defaultDoors : 'inwards' },
    });
  };

  return (
    <Row className="quoteProducts" align="center">
      {(isBifold || isCasement) && (
        <Col style={{ marginBottom: `30px` }}>
          <Alert info>
            <div
              style={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `space-between`,
                width: `100%`,
              }}
            >
              Doors open {checked ? `outwards` : `inwards`}
              <Switch onChange={() => setDoors()} checked={checked} />
            </div>
          </Alert>
        </Col>
      )}
      {data?.length > 0 &&
        data?.map(item => {
          const isActive = activeStyle === item.id;
          return (
            <Col
              className="item"
              key={item.id}
              lg={{ width: `33.333%` }}
              md={{ width: `50%` }}
            >
              <ButtonHidden
                onClick={e => setStyle(e, item.id)}
                style={{
                  ...buttonStyle,
                  backgroundColor: isActive ? `#fff` : `#f9f9f9`,
                  transform: isActive ? `scale(1.04)` : `scale(1)`,
                }}
                fallback={<ProductPlaceholder />}
              >
                <div style={checkStyle}>
                  {isActive && <Icons.Check size="18px" />}
                </div>
                {item.image ? (
                  <img
                    src={item.image}
                    style={imagePlaceholder}
                    alt={item.label}
                  />
                ) : (
                  <div style={imagePlaceholder} />
                )}
                <strong style={labelStyle}>{item.label}</strong>
              </ButtonHidden>
            </Col>
          );
        })}
    </Row>
  );
}
