import React from 'react';

import stages from './data';
import Container from '../Container';

import './stages.css';

import { StagePlaceholder } from './placeholder';
import { MainColour, TextColour, TitleColour } from '../../utils/variables';

import loadComponent from '../Loadable';

const ButtonHidden = loadComponent('button-hidden');

export default function QuoteStages({ active, setActive }) {
  return (
    <div
      className="quoteStages"
      style={{ backgroundColor: TitleColour, padding: `20px 0` }}
    >
      <Container>
        <ul style={listStyle}>
          {stages.map((stage, i) => (
            <li key={stage.title} style={itemStyle}>
              <ButtonHidden
                onClick={() => setActive(i)}
                disabled={i > active}
                style={buttonStyle}
                fallback={<StagePlaceholder />}
              >
                <div
                  className="circle"
                  style={{
                    ...circleStyle,
                    backgroundColor: active >= i ? MainColour : TextColour,
                  }}
                >
                  {i + 1}
                </div>
                <span className="label" style={labelStyle}>
                  {stage.title}
                </span>
              </ButtonHidden>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
}

const sharedStyle = { display: `flex`, alignItems: `center` };

const listStyle = { display: `flex`, justifyContent: `space-between` };

const itemStyle = { ...sharedStyle };

const buttonStyle = { ...sharedStyle };

const labelStyle = { color: `#fff`, fontWeight: `800`, textAlign: `left` };

const circleStyle = {
  ...sharedStyle,
  justifyContent: `center`,
  fontWeight: `800`,
  color: `#fff`,
};
