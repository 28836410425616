import React, { useContext, useEffect, useState } from 'react';

import { FormContext } from '../Form/context';
import { AuthUserContext } from '../Session';

import Icons from '../Icons';
import { BorderRadius, TextColour, TitleColour } from '../../utils/variables';

import { OptionPlaceholder } from './placeholder';

import './options.css';

import loadComponent from '../Loadable';
import StaticAlert from '../Alert';

const ButtonHidden = loadComponent('button-hidden');
const ButtonInput = loadComponent('button-input');
const LightboxWrapper = loadComponent('lightbox-wrapper');
const H3 = loadComponent('h3');

export default function QuoteOptions({ data }) {
  const { values, bulkChange, getValue } = useContext(FormContext);
  const authUser = useContext(AuthUserContext);

  useEffect(() => {
    if (data.length > 0) {
      const allDefaults = {};
      data.forEach(item => {
        const def = item?.options?.filter(x => x.default)[0];
        if (def && values[item.id] === undefined) {
          allDefaults[item.id] = def.id;
        }
      });
      Promise.all([allDefaults]).then(() => bulkChange(allDefaults));
    }
  }, []);

  const [options, setOptions] = useState(false);

  const closeOptions = () => {
    setOptions(false);
  };

  const activeData = data.filter(x => x.id === options)[0];

  const optionSelectProps = {
    data: activeData,
    closeOptions,
  };

  return (
    <>
      {data.map((item, i) => {
        const value = getValue(item.id);
        const active = item?.options?.filter(y => y.id === value)[0];

        const hasActive = !!active;
        const isRequired = true; /* item.required */

        const isGlass = item.id === 'glass';
        const isDisabled = isGlass && authUser === null;

        const showIcon = () => {
          if (isRequired || hasActive) {
            return <Icons.Edit style={iconStyle} />;
          }
          return <Icons.Plus style={iconStyle} />;
        };

        const optionStyle = { marginTop: i !== 0 && `15px` };

        if (isDisabled) {
          return null;
        }

        return (
          <ButtonHidden
            key={item.id}
            className="singleOption"
            onClick={() => setOptions(item.id)}
            style={{
              ...itemStyle,
              marginTop: i !== 0 && `15px`,
              opacity: isDisabled && 0.6,
            }}
            fallback={<OptionPlaceholder style={optionStyle} />}
            disabled={isDisabled}
          >
            <h2 style={labelStyle}>
              {item.label}
              {isRequired && <span>*</span>}
            </h2>

            <p style={activeStyle}>
              {active?.label || value}{' '}
              {active?.price > 0 && `(+£${active?.price})`}{' '}
              {isDisabled && `- Create an account to customize glazing options`}
            </p>

            <div className="action" style={actionStyle}>
              {showIcon()}
            </div>
          </ButtonHidden>
        );
      })}
      <LightboxWrapper
        showLightbox={!!options}
        closeLightbox={closeOptions}
        // noLoader
      >
        {options && activeData && <OptionModal {...optionSelectProps} />}
      </LightboxWrapper>
    </>
  );
}

function OptionModal({ data, closeOptions }) {
  const { handleChange, getValue } = useContext(FormContext);
  const activeValue = getValue(data.id);

  const [selected, setSelected] = useState(activeValue);

  const saveOption = () => {
    handleChange({ target: { name: data.id, value: selected } });
    closeOptions();
  };

  // const removeOption = () => {
  //   if (!data.required) {
  //     handleChange({ target: { name: data.id, value: undefined } });
  //   }
  //   closeOptions();
  // };

  return (
    <div className="optionModal" style={modalInner}>
      <H3 size="24px" margin="30px" line={1} style={modalHeader}>
        Choose your <span>{data?.label}</span>
      </H3>
      {data.type !== 'number' && (
        <StaticAlert style={{ marginBottom: `15px` }} info>
          Click the magnifying glass icon to view detailed sizes/layout for the
          selected option.
        </StaticAlert>
      )}
      {data.label === 'Doors Configuration' && (
        <StaticAlert style={{ marginBottom: `15px` }} info>
          IMPORTANT: instructions are based on view from outside.
        </StaticAlert>
      )}
      {data.type === 'number' ? (
        <label htmlFor="amount">
          <strong>{data?.label} (mm)</strong>
          <input
            id="amount"
            type="number"
            min="150"
            // max="1940"
            step={data?.interval || '10'}
            onChange={e => setSelected(e.target.value)}
            value={selected}
          />
        </label>
      ) : (
        <div style={modalSelect}>
          <div style={scrollInner}>
            {data?.options?.map((option, i) => {
              const isSelected = selected === option.id;
              return (
                <ButtonHidden
                  key={option.id}
                  onClick={() => setSelected(option.id)}
                  style={{
                    ...selectOption,
                    backgroundColor: i % 2 ? `#fff` : `#f9f9f9`,
                    fontWeight: isSelected && `700`,
                    color: isSelected ? TitleColour : TextColour,
                  }}
                >
                  {option.image && (
                    <a
                      href={option.image}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icons.View size="18px" style={{ marginRight: `30px` }} />
                    </a>
                  )}
                  <span style={{ paddingRight: `30px` }}>
                    {option.label}
                    {option.price > 0 && ` (+£${option.price})`}
                  </span>
                  {isSelected && (
                    <Icons.Check
                      colour="#155724"
                      size="18px"
                      style={{ marginLeft: `auto` }}
                    />
                  )}
                </ButtonHidden>
              );
            })}
          </div>
        </div>
      )}

      <ButtonInput
        value="Save Changes"
        onClick={() => saveOption()}
        style={modalAction}
        primary
      />
      {/* {!data.required && !!activeValue && (
        <ButtonInput
          value={`Remove ${data.label}`}
          onClick={() => removeOption()}
          style={modalAction}
          secondary
          dark
        />
      )} */}
      <ButtonHidden onClick={() => closeOptions()} style={modalClose}>
        Close Window
      </ButtonHidden>
    </div>
  );
}

const itemStyle = {
  backgroundColor: `#f9f9f9`,
  border: `2px solid rgba(0,0,0,0.2)`,
  borderRadius: BorderRadius,
  padding: `30px 45px`,
  width: `100%`,
  display: `flex`,
  flexWrap: `wrap`,
  alignItems: `center`,
  textAlign: `left`,
  height: `130px`,
};

const labelStyle = {
  lineHeight: 1,
};

const activeStyle = {
  flex: 1,
  margin: 0,
};

const actionStyle = { marginLeft: `auto` };

const iconStyle = {
  opacity: 0.75,
};

const modalInner = {
  backgroundColor: `#fff`,
  borderRadius: BorderRadius,
  width: `550px`,
  maxWidth: `100%`,
  margin: `0 auto`,
};

const modalHeader = {
  textTransform: `uppercase`,
};

const modalSelect = {
  border: `1px solid rgba(0,0,0,0.2)`,
  borderRadius: `15px`,
  height: `320px`,
  width: `100%`,
  overflowY: `hidden`,
};

const scrollInner = {
  height: `100%`,
  width: `100%`,
  overflow: `auto`,
};

const selectOption = {
  display: `flex`,
  alignItems: `center`,
  // justifyContent: `space-between`,
  padding: `18px 30px`,
  width: `100%`,
  textAlign: `left`,
};

const modalAction = {
  marginTop: `15px`,
};

const modalClose = {
  marginTop: `30px`,
  display: `block`,
  width: `100%`,
  textAlign: `center`,
  color: TextColour,
};
