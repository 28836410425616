import React, { useContext } from 'react';
import parse from 'html-react-parser';

import { FormContext } from '../Form/context';

import Row from '../Container/row';
import Col from '../Container/column';

import Icons from '../Icons';
import {
  buttonStyle,
  checkStyle,
  imagePlaceholder,
  labelStyle,
} from './styles';
import { HeaderPlaceholder, ProductPlaceholder } from './placeholder';

import './colours.css';

import loadComponent from '../Loadable';

const ButtonHidden = loadComponent('button-hidden');
const H2 = loadComponent('h2');

export default function QuoteColours({ data, handles }) {
  const { handleChange, getValue } = useContext(FormContext);

  const colours = [
    {
      id: 'exterior_colour',
      title: 'Exterior <span>Colour</span>',
      options: data,
    },
    {
      id: 'interior_colour',
      title: 'Interior <span>Colour</span>',
      options: data,
    },
    {
      id: 'handle_colour',
      title: 'Handles & Magnetic Catch <span>Colour</span>',
      options: handles,
    },
  ];

  return colours
    .filter(x => x.options && x.options.length > 0)
    .map((type, i) => {
      const setColour = (e, id) => {
        // e.preventDefault();
        handleChange({ target: { name: type.id, value: id } });
      };

      const activeColour = getValue(type.id);

      return (
        <div
          key={type.id}
          className="quoteColours"
          style={{ marginTop: i !== 0 && `60px` }}
        >
          <H2
            style={{ textTransform: `uppercase` }}
            fallback={<HeaderPlaceholder />}
          >
            {parse(type.title)}
          </H2>
          <Row align="center">
            {type.options?.length > 0 &&
              type.options?.map(item => {
                const isActive = activeColour === item.id;
                return (
                  <Col
                    key={item.id}
                    className="item"
                    xxl={{ width: `20%` }}
                    lg={{ width: `33.333%` }}
                    md={{ width: `50%` }}
                  >
                    <ButtonHidden
                      onClick={e => setColour(e, item.id)}
                      style={{
                        ...buttonStyle,
                        backgroundColor: isActive ? `#fff` : `#f9f9f9`,
                        transform: isActive ? `scale(1.04)` : `scale(1)`,
                      }}
                      fallback={<ProductPlaceholder />}
                    >
                      <div style={checkStyle}>
                        {isActive && <Icons.Check size="18px" />}
                      </div>
                      {!item.custom && (
                        <>
                          {item.image ? (
                            <img
                              src={item.image}
                              style={imagePlaceholder}
                              alt={item.label}
                            />
                          ) : (
                            <div style={imagePlaceholder} />
                          )}
                        </>
                      )}
                      <strong style={labelStyle}>{item.label}</strong>
                      {item.custom && (
                        <>
                          <input
                            type="text"
                            name={`${type.id}_code`}
                            defaultValue={getValue(`${type.id}_code`)}
                            onChange={handleChange}
                            style={customSpacing}
                          />
                          <a
                            href="https://www.ralcolorchart.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="View Colours"
                            style={customSpacing}
                          >
                            View Colours
                          </a>
                        </>
                      )}
                    </ButtonHidden>
                  </Col>
                );
              })}
          </Row>
        </div>
      );
    });
}

const customSpacing = { marginTop: `30px` };
