import React, { useContext, useEffect } from 'react';

import stages from './data';
import { FormContext } from '../Form/context';

import Container from '../Container';
import QuoteStages from './stages';
import QuoteActions from './actions';
import QuoteCallback from './callback';

import './style.css';

import loadComponents from '../Loadable';

const Alerts = loadComponents('form-alerts');

export default function QuoteLayout({
  products,
  active,
  setActive,
  nextStage,
  prevStage,
  options,
  defaultVals,
  manual,
  manualQuote,
}) {
  const { handleSubmit, bulkChange } = useContext(FormContext);

  useEffect(() => {
    if (defaultVals) {
      bulkChange(defaultVals);
    }
  }, [defaultVals]);

  const stageProps = {
    active,
    setActive,
  };

  const actionProps = {
    active,
    nextStage,
    prevStage,
  };

  const formProps = {
    data: manualQuote?.formBuilder?.formGroup[0].fields,
  };

  return (
    <form name="get-quote" onSubmit={handleSubmit}>
      <QuoteStages {...stageProps} />
      <div className="quoteInner">
        <Container>
          <Alerts />
          {!manual ? (
            <>
              {stages[active].component({ products, options })}
              <QuoteActions {...actionProps} />
            </>
          ) : (
            <QuoteCallback {...formProps} />
          )}
        </Container>
      </div>
    </form>
  );
}
