import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';
import FormFields from '../Form/fields';

import loadComponents from '../Loadable';

const Alerts = loadComponents('form-alerts');

export default function QuoteCallback(props) {
  return (
    <Row>
      <Col
        // w={{ width: `35%` }}
        // xxl={{ width: `40%` }}
        xl={{ width: `45%` }}
        lg={{ width: `50%` }}
      >
        <Alerts />
        <FormFields {...props} />
      </Col>
    </Row>
  );
}
