import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import PageTitle from '../components/Subpage/title';
import Quote from '../components/Quote';
import StaticAlert from '../components/Alert';
import Container from '../components/Container';

const page = { name: 'Get A Quote', slug: 'get-quote' };

export default function GetQuotePage({ data, location }) {
  const dataFound =
    typeof window !== `undefined` && window.history.state !== null;

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = event => {
    event.preventDefault();

    const warning = `Please note you are leaving the quote page this will clear your information from the browser and you will have to start over.`;
    event.returnValue = warning;

    return warning;
  };

  const { allWpSimpleProduct, manualQuote } = data;

  const prevPage = location.state && location.state.prevPage;
  const products = allWpSimpleProduct?.edges;

  const defaultVals = dataFound && window.history.state.edit;

  const phone = data.wp.options?.websiteOptions?.general?.phoneNumber;

  const quoteProps = {
    products,
    defaultVals,
    manualQuote,
  };

  return (
    <Layout
      title={page.name}
      location={`/${page.slug}/`}
      padding="0"
      prevPage={prevPage}
      quote
    >
      <article>
        <PageTitle title={page.name} margin="0" />
        <Container>
          <div style={{ padding: `60px 0` }}>
            <StaticAlert info>
              Under construction - Call us for a quote on: {phone}
            </StaticAlert>
          </div>
        </Container>
        {/* <Quote {...quoteProps} /> */}
      </article>
    </Layout>
  );
}

export const QuotePageData = graphql`
  query GetQuoteQuery {
    wp {
      ...ContactDetails
    }
    allWpSimpleProduct(sort: { fields: date, order: ASC }) {
      edges {
        node {
          name
          slug
          databaseId
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 310, height: 150)
              }
            }
          }
          brochureDownload {
            brochures {
              label
              download {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    manualQuote: wpForm(databaseId: { eq: 2085 }) {
      ...FormFormBuilder
    }
  }
`;
